import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Suspense, useContext, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import "./App.scss";
import { getKaribuAdGroups } from "./app/config/env";

import Loader from "./app/pages/common/loader/loader";
import { LayoutInit } from "./app/pages/layouts/core/LayoutInit";
import MsalGroup from "./app/providers/msal-groups/MsalGroup";

import "./app/styles/mainStyles.scss";
import { generateToken } from "./app/firebase/firebase";
import AuthContext from "./app/contexts/auth-context";

const App = () => {
  const adGroups = useMemo(() => getKaribuAdGroups(), []);
  const authState = useContext(AuthContext);

  useEffect(() => {
    if (authState?.authContextData?.currentUser) {
      generateToken();
    }
  }, [authState?.authContextData?.currentUser]);

  return (
    <Suspense fallback={<Loader />}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MsalGroup acceptedAzureGroupIds={adGroups}>
            <LayoutInit>
              <Outlet />
            </LayoutInit>
          </MsalGroup>
        </LocalizationProvider>
    </Suspense>
  );
};

export default App;
